<div class="app-sidebar">
  <ul class="list">
    @if(modulesList){ @for (module of modulesList; track $index) {
    <li
      [pTooltip]="module.module_group"
      [tooltipDisabled]="hideTooltip"
      tooltipPosition="right"
      placeholder="Right"
    >
      <span
        class="list-title"
        [class.active]="module.id === currentActiveGroupId"
        (click)="expandOrCollapseGroup(module.id)"
      >
        <i
          class="icon-module-grp"
          [attr.module-grp-name]="module.module_group"
        ></i>
        <span
          [ngClass]="isSideBarExpanded ? 'forward-motion' : 'backward-motion'"
          >{{ module.module_group }}</span
        >
        <i *ngIf="isSideBarExpanded" class="fa fa-chevron-down"></i>
      </span>
      <ul
        [ngClass]="
          isSideBarExpanded ? 'visible-side-bar' : 'in-visible-side-bar'
        "
        class="list inner"
        [@content]="
          module.id === currentActiveGroupId
            ? {
                value: 'visible',
              }
            : {
                value: 'hidden',
              }
        "
      >
        <div class="content">
          @for (subMenu of module.children; track $index) {
          <li
            (click)="navigateTo(subMenu?.routing_url)"
            [class.addActiveColor]="router.url.startsWith(subMenu.routing_url)"
          >
            {{ subMenu.module_name }}
          </li>
          }
        </div>
      </ul>
    </li>
    } }
  </ul>
  <div class="sidebar-btn-outer">
    <button
      class="sidebar-btn"
      [ngClass]="hideTooltip ? 'close-arrow' : 'open-arrow'"
      (click)="toggleSideNavBar()"
      [pTooltip]="hideTooltip ? sidebarArrowClose : sidebarArrowOpen"
      tooltipPosition="right"
      placeholder="Right"
    ></button>
  </div>
</div>
