import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { filter } from 'rxjs';
import { ClientPortalService } from '../../../services/clientPortalService/client-portal.service';
import { UserService } from '../../../services/user-service/user.service';
@Component({
  selector: 'rcp-sidebar',
  standalone: true,
  imports: [CommonModule, TooltipModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  animations: [
    trigger('content', [
      state(
        'hidden',
        style({
          height: '0',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
        })
      ),
      transition('visible <=> hidden', [animate('200ms ease-in-out')]),
      transition('void => *', animate(0)),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  isSideBarExpanded = true;
  hideTooltip = true;
  sidebarArrowClose = 'Close';
  sidebarArrowOpen = 'Open';
  sidebarclose = 'side-bar-close';
  clientsFromUserDetails: any[];
  modulesList: any[];
  permittedRoutes: any[];
  currentActiveGroupId;
  router = inject(Router);
  clientPortalService = inject(ClientPortalService);
  userService = inject(UserService);
  activeSnapShot = inject(ActivatedRoute);
  /**
   * Navigation bar behaviour
   * 1) If user clicks on any submenu (like Inquiry) Desk then we will collpase the side bar and all its children.
   * 2) On Page load we will expand side menu bar and will highligh correct submenu
   * 3) On inner pages like order details or sla list or inventory details we will expand side bar but will show
   * all menu in collapsed state
   * 4) If user changes client id or warehouse then we will expand side nav bar and will highligh correct submenu
   * 5) If user clicks on radial logo then we will expand side nav bar and will highligh correct submenu
   *
   */
  ngOnInit(): void {
    /**
     * below will get triggered when we get menu from user details api
     */
    this.clientPortalService.logInUserDetails$
      .pipe(filter((data) => data?.length > 0))
      .subscribe((data: any) => {
        this.getClientListFromUserDetails();
      });
    /**
     * below code will get triggered when client change from client warehouse dropdown
     */
    this.clientPortalService.currentSelectedClient$.subscribe(
      (activeClient) => {
        this.getClientListFromUserDetails(activeClient);
      }
    );
    /**
     * below code will expand appropriate menu group
     */
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url === '/') {
          this.navigateTo(this.permittedRoutes[0] || 'no-access');
          return;
        }
        const extraRouteData = this.getRouteSpecificData(this.activeSnapShot);
        /**
         * below code will make sure that if active route is child then we will follow
         * point 3 mentioned in how side nav bar behave
         * "parent_route" key we are setting in app.route.ts
         */
        if (extraRouteData['parent_route']) {
          console.log('active route is child route');
          this.currentActiveGroupId = undefined;
        } else {
          this.currentActiveGroupId =
            this.clientPortalService.getModuleGrpFromURL(e.url);
        }
      }
    });
  }
  getRouteSpecificData(route: ActivatedRoute) {
    /**
     * for every route we are setting some data in app.route.ts
     */
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.data;
  }
  getClientListFromUserDetails(activeClient = undefined) {
    let selectedCompany = activeClient;
    if (!selectedCompany) {
      this.clientsFromUserDetails =
        this.clientPortalService.getAllowedClientsForLoggedInUser();
      selectedCompany = sessionStorage.getItem('companyname')
        ? sessionStorage.getItem('companyname')
        : this.clientsFromUserDetails[0].client_acronym;
    }
    this.getModulesByClient(selectedCompany);
  }
  getModulesByClient(client: string) {
    this.modulesList = this.clientPortalService.getModulesByClient(client);
    this.permittedRoutes = this.clientPortalService.getPermittedRoute(client);
    this.clientPortalService.logInUserModules.next(this.modulesList);
    /**
       * as of now we are not adding any configuration related menu options
       this.modulesList = this.clientPortalService.addConfigMenuOptions(
         this.modulesList
       );
      */
    this.clientPortalService.permittedMenuItems = this.permittedRoutes;
    this.currentActiveGroupId = this.modulesList[0].id;
    this.navigateTo(this.permittedRoutes[0] || 'no-access', true);
  }
  navigateTo(url, shouldExpandSideBar = false) {
    this.router.navigate([url]);
    this.isSideBarExpanded = shouldExpandSideBar;
    this.hideTooltip = shouldExpandSideBar;
  }
  expandOrCollapseGroup(moduleId: number) {
    // if same menu group is clicked
    if (this.currentActiveGroupId === moduleId && this.isSideBarExpanded) {
      this.currentActiveGroupId = undefined;
      this.hideTooltip = true;
      return;
    }
    this.isSideBarExpanded = true;
    this.hideTooltip = true;
    this.currentActiveGroupId = moduleId;
  }
  toggleSideNavBar() {
    this.isSideBarExpanded = !this.isSideBarExpanded;
    this.hideTooltip = !this.hideTooltip;
  }
}
