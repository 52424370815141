import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(public messageService: MessageService) {}
  showSuccess(summary: string, detail: string, life?) {
    this.messageService.clear();
    this.messageService.add({
      severity: 'success',
      summary,
      detail,
      icon: 'pi pi-custom-success-icon',
      life,
    });
  }
  showError(summary: string, detail: string, life?) {
    this.messageService.clear();
    this.messageService.add({
      severity: 'error',
      summary,
      detail,
      icon: 'pi pi-custom-error-icon',
      life,
    });
  }
  clearSnackeBar() {
    this.messageService.clear();
  }
}
